import { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import AddClient from "./AddClient";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { CSVDownload } from "react-csv";
import { useAuth } from "../../../context/useAuth";
import UpdateClient from "./UpdateClient";
import { useLocation } from "react-router-dom";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";
import DataTable from "react-data-table-component";
import { ScaleLoader } from "react-spinners";
import ClientTable from "./ClientTable";

const Clients = () => {
  const location = useLocation();
  const { Clientvalue } = location.state || "All";
  const programId = useAuth().programId;
  const today = new Date(Date.now());
  const token = useAuth().token;
  const role = useAuth().role;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [clients, setClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [data1, setData1] = useState([]);
  var [data2, setData2] = useState([]);
  var [data3, setData3] = useState([]);
  const [ComplianceRate, setComplianceRate] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredValue, setFilteredValue] = useState("All");
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [download, setDownload] = useState(false);
  // const [filteredNumberValue setFilteredNumberValue] = useState("")

  let dataForCSV = [
    [
      "AppointmentID",
      "Client",
      "Department",
      "Subdepartment",
      "StartTime",
      "EndTime",
      "Status",
      "Attendance",
      "Date",
      "Notes",
    ],
  ];

  let clientDataForCSV = [
    [
      "Id",
      "Name",
      "Date of Birth",
      "Intake Date",
      "SUD DOA",
      "Program Completion Date",
      "Discharge Date",
      "Days",
      "SAIOP AM or PM Group",
      "Active Status",
      "Email",
      "Phone",
      "Updated By",
      "Notes",
      "Created By",
    ],
  ];

  const [csvData, setCsvData] = useState(dataForCSV);
  const [clientDownload, setClientDownload] = useState(false);
  const [clientCSV, setClientCSV] = useState(clientDataForCSV);

  const dateSortForCompliance = (rowA, rowB) => {
    const dateA = new Date(rowA.ProgramCompletionDate);
    const dateB = new Date(rowB.ProgramCompletionDate);
    return dateA - dateB;
  };

  const dateSortForIntake = (rowA, rowB) => {
    const dateA = new Date(rowA.IntakeDate);
    const dateB = new Date(rowB.IntakeDate);
    return dateA - dateB;
  };

  const dateSortForDOB = (rowA, rowB) => {
    const dateA = new Date(rowA.dob);
    const dateB = new Date(rowB.dob);
    return dateA - dateB;
  };

  const dateSortForSUD_DOA = (rowA, rowB) => {
    const dateA = new Date(rowA.sud_doa);
    const dateB = new Date(rowB.sud_doa);
    return dateA - dateB;
  };

  const dateSortForDischarge = (rowA, rowB) => {
    const dateA = new Date(rowA.DischargeDate);
    const dateB = new Date(rowB.DischargeDate);
    return dateA - dateB;
  };

  const clientsColumns = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "190px",
    },
    {
      name: "Date of Birth",
      selector: (row) => convertTimeToDate(row.dob),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForDOB,
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForIntake,
    },
    {
      name: "SUD DOA",
      selector: (row) => convertTimeToDate(row.sud_doa),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForSUD_DOA,
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "240px",
      sortFunction: dateSortForCompliance,
      cell: (row) => (
        <div
          style={{
            color:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "#BB2B3D"
                : "black",
            fontWeight:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "bold"
                : "",
          }}
        >
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Discharge Date",
      selector: (row) => convertTimeToDate(row.DischargeDate),
      sortable: true,
      width: "170px",
      sortFunction: dateSortForDischarge,
    },
    {
      name: "SAIOP AM or PM Group",
      selector: (row) => row.clientAMPMGroup,
      sortable: true,
      width: "230px",
    },

    {
      name: "Active Status",
      selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          style={{
            color: row.ActiveStatus == 1 ? "black" : "#BB2B3D",
            fontWeight: row.ActiveStatus == 1 ? "" : "bold",
          }}
        >
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.Email == "" ? "No Email" : row.Email),
      sortable: true,
      width: "230px",
    },
    {
      name: "Phone",
      selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
      sortable: true,
      width: "230px",
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.ClientID)}
        >
          Action
        </button>
      ),
      width: "250px",
    },
  ];

  const ampmSort = (rowA, rowB) => {
    const valueA = rowA.clientAMPMGroup;
    const valueB = rowB.clientAMPMGroup;

    if (valueA === null) return 1;
    if (valueB === null) return -1;

    return valueA.localeCompare(valueB);
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        fontSize: "15px",
        color: "black",
        textAlign: "center",
      },
    },
    table: {
      style: {
        width: "100%",
        textAlign: "center",
      },
    },
  };

  const clientsColumnsForSuperAdmin = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "230px",
    },
    {
      name: "Date of Birth",
      selector: (row) => convertTimeToDate(row.dob),
      sortable: true,
      width: "180px",
      sortFunction: dateSortForDOB,
      // cell: (row) => (
      //   <div>
      //     <button
      //       type="button"
      //       class="btn btn-secondary"
      //       data-toggle="tooltip"
      //       data-placement="top"
      //       title="Tooltip on top"
      //     >
      //       Tooltip on top
      //     </button>
      //   </div>
      // ),
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.ClientID)}
        >
          Action
        </button>
      ),
      width: "150px",
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForIntake,
    },
    {
      name: "SUD DOA",
      selector: (row) => convertTimeToDate(row.sud_doa),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForSUD_DOA,
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "240px",
      sortFunction: dateSortForCompliance,
      cell: (row) => (
        <div
          style={{
            color:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "#BB2B3D"
                : "black",
            fontWeight:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "bold"
                : "",
          }}
        >
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Discharge Date",
      selector: (row) => convertTimeToDate(row.DischargeDate),
      sortable: true,
      width: "170px",
      sortFunction: dateSortForDischarge,
    },
    {
      name: "Days",
      selector: (row) => row.daysBetweenIntakeAndDischarge,
      sortable: true,
      width: "100px",
    },
    {
      name: "SAIOP AM or PM Group",
      selector: (row) => row.clientAMPMGroup,
      sortable: true,
      width: "230px",
      sortFunction: ampmSort,
    },
    {
      name: "Active Status",
      selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          style={{
            color: row.ActiveStatus == 1 ? "black" : "#BB2B3D",
            fontWeight: row.ActiveStatus == 1 ? "" : "bold",
          }}
        >
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.Email == "" ? "No Email" : row.Email),
      sortable: true,
      width: "180px",
    },
    {
      name: "Phone",
      selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
      sortable: true,
      width: "180px",
    },
    {
      name: "Updated by",
      selector: (row) => row.updated_by,
      sortable: true,
      width: "170px",
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: true,
      width: "250px",
    },
    {
      name: "Created by",
      selector: (row) => row.created_by,
      sortable: true,
      width: "200px",
    },
  ];

  const singleClientColumns = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "230px",
    },
    {
      name: "Date of Birth",
      selector: (row) => convertTimeToDate(row.dob),
      sortable: true,
      width: "180px",
      sortFunction: dateSortForDOB,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.ClientID)}
        >
          Action
        </button>
      ),
      width: "150px",
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForIntake,
    },
    {
      name: "SUD DOA",
      selector: (row) => convertTimeToDate(row.sud_doa),
      sortable: true,
      width: "150px",
      sortFunction: dateSortForSUD_DOA,
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "240px",
      sortFunction: dateSortForCompliance,
      cell: (row) => (
        <div
          style={{
            color:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "#BB2B3D"
                : "black",
            fontWeight:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "bold"
                : "",
          }}
        >
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Discharge Date",
      selector: (row) => convertTimeToDate(row.DischargeDate),
      sortable: true,
      width: "170px",
      sortFunction: dateSortForDischarge,
    },
    {
      name: "Days",
      selector: (row) => row.daysBetweenIntakeAndDischarge,
      sortable: true,
      width: "100px",
    },
    {
      name: "SAIOP AM or PM Group",
      selector: (row) => row.clientAMPMGroup,
      sortable: true,
      width: "230px",
      sortFunction: ampmSort,
    },
    {
      name: "Active Status",
      selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          style={{
            color: row.ActiveStatus == 1 ? "black" : "#BB2B3D",
            fontWeight: row.ActiveStatus == 1 ? "" : "bold",
          }}
        >
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.Email == "" ? "No Email" : row.Email),
      sortable: true,
      width: "180px",
    },
    {
      name: "Phone",
      selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
      sortable: true,
      width: "180px",
    },
    {
      name: "Compliance Rate",
      selector: (row) => `${ComplianceRate}`,
      sortable: true,
      width: "180px",
    },
    {
      name: "Updated by",
      selector: (row) => row.updated_by,
      sortable: true,
      width: "170px",
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: true,
      width: "250px",
    },
    {
      name: "Created by",
      selector: (row) => row.created_by,
      sortable: true,
      width: "200px",
    },
  ];

  const appointmentColumns = [
    {
      name: "Id",
      selector: (row) => row.AppointmentID,
      sortable: true,
      width: "5%",
    },
    {
      name: "Client",
      selector: (row) => findValueAfterColon("client", row.ClientID),
      sortable: true,
      width: "20%",
    },
    {
      name: "Department",
      selector: (row) => row.DepartmentName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Subdepartment",
      selector: (row) => row.SubdepartmentName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Start Time",
      selector: (row) => convertTimeToHHMM(row.StartTime),
      sortable: true,
      width: "10%",
    },
    {
      name: "End Time",
      selector: (row) => convertTimeToHHMM(row.EndTime),
      sortable: true,
      width: "10%",
    },
    {
      name: "Attendance",
      selector: (row) =>
        row.Attendance === 1
          ? row.excused_absence_comment === null
            ? "Marked as Present"
            : "Excused Absence"
          : row.Attendance === 0
          ? "Marked as Absent"
          : row.Attendance === 2
          ? "Cancelled"
          : new Date(row.Date) <= new Date()
          ? "Unchecked"
          : "Future Booking",
      sortable: true,
      width: "10%",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.Date),
      sortable: true,
      width: "15%",
    },
  ];

  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  // downloadClientCSV;
  function downloadClientCSV() {
    setClientDownload(true);
    setTimeout(() => {
      setClientDownload(false);
    }, 500);
  }

  function findValueAfterColon(type, Id) {
    if (type == "dept") {
      for (var i = 0; i < Departments.length; i++) {
        if (Departments[i].split(":")[0] == Id.toString()) {
          return Departments[i].split(":")[1];
        }
      }
    } else if (type == "subdept") {
      for (var j = 0; j < Subdepartments.length; j++) {
        if (Subdepartments[j].split(":")[0] == Id.toString()) {
          return Subdepartments[j].split(":")[1];
        }
      }
    } else {
      for (var k = 0; k < clients.length; k++) {
        if (clients[k].split(":")[0] == Id.toString()) {
          return clients[k].split(":")[1];
        }
      }
    }
  }

  function convertTimeToDate(dateString) {
    if (!dateString || dateString === "null") {
      return "";
    }
    const newDate = moment.utc(dateString).format("MM/DD/YYYY");
    return newDate;
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
  };

  const handleClientButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await getClients();
  };

  const differentDays = (date1, date2, date3) => {
    let d2;

    if (date2 === "null" || date2 === null) {
      d2 = null;
    } else {
      d2 = new Date(date2);
    }

    const d1 = new Date(date1);
    // const d2 = new Date(date2);
    const d3 = new Date(date3);

    if (d2 === null) {
      let oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(d1 - d3) / oneDay);
    } else {
      let oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(d1 - d2) / oneDay);
    }
  };

  const getStatusInString = (status) => {
    if (status === 1) {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      setFilteredValue(filteredValue);

      data.sort((a, b) => b.id - a.id);

      var temp = [];
      var temp2 = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
          temp2.push(data[i]);
        }
        resolve(temp);
      });

      let temp1 = [];

      for (let i = 0; i < temp2.length; i++) {
        temp1.push({
          ClientID: temp2[i]["id"],
          name: temp2[i]["client_name"],
          IntakeDate: temp2[i]["program_intake_date"],
          ProgramCompletionDate: temp2[i]["program_completion_date"],
          ActiveStatus: temp2[i]["status"],
          ComplianceRate: temp2[i]["ComplianceRate"],
          Email: temp2[i]["email"],
          Phone: temp2[i]["phone"],
          updated_at: temp2[i]["updated_at"],
          updated_by: temp2[i]["username"],
          created_by: temp2[i]["created_by"],
          notes: temp2[i]["notes"],
          DischargeDate: temp2[i]["discharge_date"],
          daysBetweenIntakeAndDischarge: await differentDays(
            temp2[i]["program_intake_date"],
            temp2[i]["discharge_date"],
            temp2[i]["program_completion_date"]
          ),
          dob: temp2[i]["dob"],
          clientAMPMGroup: temp2[i]["clientAMPMGroup"],
          sud_doa: temp2[i]["sud_doa"],
        });

        clientDataForCSV.push([
          temp2[i]["id"],
          temp2[i]["client_name"],
          convertTimeToDate(temp2[i]["dob"]),
          convertTimeToDate(temp2[i]["program_intake_date"]),
          temp2[i]["sud_doa"],
          convertTimeToDate(temp2[i]["program_completion_date"]),
          convertTimeToDate(temp2[i]["discharge_date"]),
          differentDays(
            temp2[i]["program_intake_date"],
            temp2[i]["discharge_date"],
            temp2[i]["program_completion_date"]
          ),
          temp2[i]["clientAMPMGroup"],
          getStatusInString(temp2[i]["status"]),
          temp2[i]["email"],
          temp2[i]["phone"],
          temp2[i]["username"],
          temp2[i]["notes"],
          temp2[i]["created_by"],
        ]);
      }

      setClientCSV(clientDataForCSV);

      setClients(temp);
      setFilteredData(temp1);
      setData2(temp1);
      setClientLoading(false);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(":")[0]);
        }
      });
      resolve(-1);
    });

    setData3(data2.filter((elem) => elem["ClientID"] == clientID));

    const apiUrl = `${base_url}/appointment/getByClient`;
    let payload = {
      clientID: clientID,
      programId: programId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let result = await response.json();
        let temp = [];
        let sum = 0;

        for (var i = 0; i < result.length; i++) {
          sum = sum + result[i]["attendance"];
          temp.push({
            AppointmentID: result[i]["id"],
            ClientID: result[i]["client_id"],
            ClientName: result[i]["client_name"],
            DepartmentName: result[i]["department_name"],
            SubdepartmentName: result[i]["sub_department_name"],
            StartTime: result[i]["start_time"],
            EndTime: result[i]["end_time"],
            Attendance: result[i]["attendance"],
            excused_absence_comment: result[i]["excused_absence_comment"],
            Date: result[i]["date"],
          });
          dataForCSV.push([
            result[i]["id"],
            findValueAfterColon("client", result[i]["client_id"]),
            findValueAfterColon("dept", result[i]["department_id"]),
            findValueAfterColon("subdept", result[i]["sub_department_id"]),
            convertTimeToHHMM(result[i]["start_time"]),
            convertTimeToHHMM(result[i]["end_time"]),
            result[i]["attendance"]
              ? "Present"
              : result[i]["attendance"]
              ? "Absent"
              : "Null",
            convertTimeToDate(result[i]["date"]),
            result[i]["notes"],
          ]);
        }

        setData1(temp);

        let length = temp.length;
        let Total = parseInt(sum) / parseInt(length);
        setComplianceRate(Total * 100);

        setVisible(true);
        setActiveDropdown(false);
        setCsvData(dataForCSV);
        setLoading(false);
      })
      .then((data) => {
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleClientDropdownChange(e) {
    setFilteredValue(e);

    if (e === "All") {
      clientDataForCSV = [
        [
          "Id",
          "Name",
          "Date of Birth",
          "Intake Date",
          "SUD DOA",
          "Program Completion Date",
          "Discharge Date",
          "Days",
          "SAIOP AM or PM Group",
          "Active Status",
          "Email",
          "Phone",
          "Updated By",
          "Notes",
          "Created By",
        ],
      ];

      for (let i = 0; i < data2.length; i++) {
        clientDataForCSV.push([
          data2[i]["ClientID"],
          data2[i]["name"],
          convertTimeToDate(data2[i]["dob"]),
          convertTimeToDate(data2[i]["IntakeDate"]),
          data2[i]["sud_doa"],
          convertTimeToDate(data2[i]["ProgramCompletionDate"]),
          convertTimeToDate(data2[i]["DischargeDate"]),
          differentDays(
            data2[i]["IntakeDate"],
            data2[i]["DischargeDate"],
            data2[i]["ProgramCompletionDate"]
          ),
          data2[i]["clientAMPMGroup"],
          getStatusInString(data2[i]["ActiveStatus"]),
          data2[i]["Email"],
          data2[i]["Phone"],
          data2[i]["updated_by"],
          data2[i]["notes"],
          data2[i]["created_by"],
        ]);
      }
      setClientCSV(clientDataForCSV);
      setFilteredData(data2);
    }

    if (e === "Active") {
      const newCLientData = data2.filter(
        (element) => element.ActiveStatus === 1
      );

      // console.log(`newClient : `, newCLientData);

      clientDataForCSV = [
        [
          "Id",
          "Name",
          "Date of Birth",
          "Intake Date",
          "SUD DOA",
          "Program Completion Date",
          "Discharge Date",
          "Days",
          "SAIOP AM or PM Group",
          "Active Status",
          "Email",
          "Phone",
          "Updated By",
          "Notes",
          "Created By",
        ],
      ];

      for (let i = 0; i < newCLientData.length; i++) {
        clientDataForCSV.push([
          newCLientData[i]["ClientID"],
          newCLientData[i]["name"],
          convertTimeToDate(newCLientData[i]["dob"]),
          convertTimeToDate(newCLientData[i]["IntakeDate"]),
          newCLientData[i]["sud_doa"],
          convertTimeToDate(newCLientData[i]["ProgramCompletionDate"]),
          convertTimeToDate(newCLientData[i]["DischargeDate"]),
          differentDays(
            newCLientData[i]["IntakeDate"],
            newCLientData[i]["DischargeDate"],
            newCLientData[i]["ProgramCompletionDate"]
          ),
          newCLientData[i]["clientAMPMGroup"],
          getStatusInString(newCLientData[i]["ActiveStatus"]),
          newCLientData[i]["Email"],
          newCLientData[i]["Phone"],
          newCLientData[i]["updated_by"],
          newCLientData[i]["notes"],
          newCLientData[i]["created_by"],
        ]);
      }
      setClientCSV(clientDataForCSV);
      setFilteredData(newCLientData);
    }

    if (e === "Inactive") {
      const newInactiveClientData = data2.filter(
        (element) => element.ActiveStatus === 0
      );

      clientDataForCSV = [
        [
          "Id",
          "Name",
          "Date of Birth",
          "Intake Date",
          "SUD DOA",
          "Program Completion Date",
          "Discharge Date",
          "Days",
          "SAIOP AM or PM Group",
          "Active Status",
          "Email",
          "Phone",
          "Updated By",
          "Notes",
          "Created By",
        ],
      ];
      for (let i = 0; i < newInactiveClientData.length; i++) {
        clientDataForCSV.push([
          newInactiveClientData[i]["ClientID"],
          newInactiveClientData[i]["name"],
          convertTimeToDate(newInactiveClientData[i]["dob"]),
          convertTimeToDate(newInactiveClientData[i]["IntakeDate"]),
          newInactiveClientData[i]["sud_doa"],
          convertTimeToDate(newInactiveClientData[i]["ProgramCompletionDate"]),
          convertTimeToDate(newInactiveClientData[i]["DischargeDate"]),
          differentDays(
            newInactiveClientData[i]["IntakeDate"],
            newInactiveClientData[i]["DischargeDate"],
            newInactiveClientData[i]["ProgramCompletionDate"]
          ),
          newInactiveClientData[i]["clientAMPMGroup"],
          getStatusInString(newInactiveClientData[i]["ActiveStatus"]),
          newInactiveClientData[i]["Email"],
          newInactiveClientData[i]["Phone"],
          newInactiveClientData[i]["updated_by"],
          newInactiveClientData[i]["notes"],
          newInactiveClientData[i]["created_by"],
        ]);
      }
      setClientCSV(clientDataForCSV);
      setFilteredData(newInactiveClientData);
    }
  }

  function handleActionClick(e) {
    const newData = filteredData.filter((value) => {
      return value.ClientID === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  }

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getClients();
    // handleClientDropdownChange(filteredValue);
  };

  useEffect(() => {
    setClientLoading(true);
    getClients();
  }, []);

  useEffect(() => {
    // console.log(`clientval : `, Clientvalue);
    if (Clientvalue === undefined || Clientvalue === null) {
      // Clientvalue = "All";
      handleClientDropdownChange(filteredValue);
    } else {
      handleClientDropdownChange(Clientvalue);
    }
  }, [data2]);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Clients</h3>
      <hr />
      <button
        type="button"
        onClick={handleClientButtonClick}
        className="btn btn-primary mb-3"
      >
        Add a Client
      </button>
      <form onSubmit={handleSubmit} className="">
        <div className=" row">
          <div className="row mt-2 w-75 mb-3">
            <Typeahead
              className="w-50"
              id="client"
              labelKey={(option) => `${option.split(":")[1]}`}
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem)}
              selected={client}
              placeholder="Select a Client"
            />
            {loading ? (
              <div className="" role="status">
                <span className="">Loading...</span>
              </div>
            ) : (
              <button
                type="Submit"
                className="btn btn-primary "
                style={{ width: "100px" }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <AddClient isOpen={isModalOpen} onClose={closeModal} />
      </form>
      {activeDropdown && (
        <div className="d-flex justify-content-space gap-5 align-items-center mt-3">
          <select
            id="dropdown"
            value={filteredValue}
            onChange={(e) => {
              handleClientDropdownChange(e.target.value);
            }}
            className="form-select w-25"
            aria-label="Default select example"
          >
            <option value="All">All Clients</option>
            <option value="Active">Active Clients</option>
            <option value="Inactive">InActive Client</option>
          </select>
          <button
            type="button"
            className="btn btn-success"
            // style={{ height: "20px" }}
            onClick={downloadClientCSV}
          >
            Download CSV
          </button>
          {clientDownload ? (
            <CSVDownload
              data={clientCSV}
              filename="export.csv"
              target="_blank"
            />
          ) : (
            <></>
          )}{" "}
        </div>
      )}

      <br />
      {visible ? (
        <>
          <button
            type="button"
            className="btn btn-success"
            onClick={downloadCSV}
          >
            Download CSV
          </button>{" "}
          &nbsp;&nbsp;
          {download ? (
            <CSVDownload data={csvData} filename="export.csv" target="_blank" />
          ) : (
            <></>
          )}{" "}
          <hr />
          <div>
            <h5 className="text-center">
              Appointment Details ({data1.length})
            </h5>
            <DataTable
              columns={appointmentColumns}
              data={data1}
              pagination
              customStyles={customStyles}
              highlightOnHover
            />
          </div>
          <br /> <br />
          <DataTable
            columns={singleClientColumns}
            data={data3}
            pagination
            customStyles={customStyles}
            highlightOnHover
          />
          {isActionModalopen && (
            <UpdateClient
              isOpen={isActionModalopen}
              onClose={closeActionModal}
              data={actionModalData}
            />
          )}
        </>
      ) : (
        <>
          {clientLoading === true ? (
            <>
              <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <ScaleLoader size={60} />
              </div>
            </>
          ) : (
            <>
              {role === "Super Admin" ? (
                <>
                  <ClientTable
                    clientData={filteredData}
                    handleActionClick={handleActionClick}
                  />
                  {/* <DataTable
                    columns={clientsColumnsForSuperAdmin}
                    data={filteredData}
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                  /> */}
                </>
              ) : (
                <DataTable
                  columns={clientsColumns}
                  data={filteredData}
                  pagination
                  customStyles={customStyles}
                  highlightOnHover
                />
              )}
            </>
          )}

          {isActionModalopen && (
            <UpdateClient
              isOpen={isActionModalopen}
              onClose={closeActionModal}
              data={actionModalData}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Clients;
