import { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { CSVDownload } from "react-csv";
import { useAuth } from "../../../context/useAuth";
import AddUser from "./AddUser";
import LogoutModal from "../../logoutModal/LogoutModal";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import ChangePassword from "./ChangePassword";

const User = () => {
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  const [isModelChangePasswordOpen, setIsModelChangePasswordOpen] =
    useState(false);
  const [actionModalData, setActionModalData] = useState([]);

  const handleChangePassword = (e) => {
    const newData = data.filter((value) => {
      return value.UserID === e;
    });

    setActionModalData(newData);
    setIsModelChangePasswordOpen(true);
  };

  const [download, setDownload] = useState(false);

  var dataForCSV = [
    ["UserId", "Name", "Role", "Department Id", "Department Name"],
  ];

  var [csvData, setCsvData] = useState();
  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  const handleClientButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await getUsers();
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        fontSize: "16px",
        color: "black",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.Status === 0,
      style: {
        backgroundColor: "#fce2e1", // Red background if status is 0
        // color: "white", // White text for better contrast
      },
    },
  ];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.UserID,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.UserName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Role",
      selector: (row) => row.Role,
      sortable: true,
      width: "8%",
    },
    {
      name: "Department Id",
      selector: (row) => row.DepartmentID,
      sortable: true,
      width: "8%",
    },
    {
      name: "Department Name",
      selector: (row) => row.DepartmentName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Updated By",
      selector: (row) => findUserName(row.updatedBy),
      sortable: true,
      width: "15%",
    },
    {
      name: "Updated Time",
      selector: (row) =>
        `${new Date(row.updatedTime).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })} ET`,
      sortable: true,
      width: "13%",
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.Status === 0) {
          return "Disabled";
        } else {
          return "Enabled";
        }
      },
      width: "7%",
    },
    {
      name: "Action",
      cell: (row) => (
        <div
          style={
            {
              // display: "flex",
              // justifyContent: "center",
              // width: "100%",
              // gap: "10px",
            }
          }
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleChangePassword(row.UserID)}
          >
            Update Details
          </button>
          {/* <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDelete(row.UserID)}
          >
            Delete
          </button> */}
        </div>
      ),
      width: "14%",
    },
  ];

  const getUsers = async () => {
    try {
      const response = await fetch(`${base_url}/user/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      for (var i = 0; i < data.length; i++) {
        dataForCSV.push([
          data[i]["UserID"],
          data[i]["UserName"],
          data[i]["Role"],
          data[i]["DepartmentID"],
          data[i]["DepartmentName"],
        ]);
      }
      setCsvData(dataForCSV);
      setData(data);
    } catch (err) {
      // console.log(`error`);
    }
  };

  const handleDelete = (e) => {
    const apiUrl = `${base_url}/user/delete/${e}`;
    if (window.confirm("Are you sure you want to delete this user?") == false) {
      toast.info("user deletion cancelled");
      return;
    }

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        // if (response.status === 404) {
        //   toast.warn(
        //     "Appointments already exist that were created by this user. Please delete them first."
        //   );
        //   return;
        // } else
        if (response.status === 200) {
          toast.success("user deleted successfully");
          getUsers();
          return;
        } else {
          setIsExpire(true);
          return;
        }
      })
      .catch((error) => {
        toast.error("Error deleting user");
        console.error("Error sending data:", error);
      })
      .finally(() => {
        // onClose();
      });
  };

  function findUserName(e) {
    const userName = data.find((element) => element.UserID === e);

    if (userName != null) {
      return userName.UserName;
    } else {
      return "";
    }
  }

  const closeActionModal = () => {
    setIsModelChangePasswordOpen(false);
    getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      {isModelChangePasswordOpen && (
        <ChangePassword
          isOpen={isModelChangePasswordOpen}
          onClose={closeActionModal}
          data={actionModalData}
        />
      )}
      <h3 className="pt-4">User</h3>
      <hr />

      <div className="col">
        <div className="row mt-2">
          <button
            type="button"
            onClick={handleClientButtonClick}
            className="btn btn-primary ms-3 mb-3"
            style={{ width: "180px" }}
          >
            Add User
          </button>
        </div>
      </div>
      <AddUser isOpen={isModalOpen} onClose={closeModal} />

      <>
        <button
          type="button"
          className="btn btn-success mb-3"
          onClick={downloadCSV}
        >
          Download CSV
        </button>
        {download ? <CSVDownload data={csvData} target="_blank" /> : <></>}
      </>
      <>
        <DataTable
          fixedHeader
          customStyles={customStyles}
          title="User"
          highlightOnHover
          pagination
          columns={columns}
          data={data}
          conditionalRowStyles={conditionalRowStyles}
        />
      </>
    </div>
  );
};

export default User;
