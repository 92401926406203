import ClientComplienceReport from "../components/optionScreens/clientComplienceReport/ClientComplienceReport";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const PrintableComponent = () => {
  return (
    <div>
      <h1>This is the component to print</h1>
      <p>You can style this as needed for the printed version.</p>
      {/* <ClientComplienceReport /> */}
    </div>
  );
};

const ClientComplienceReportPage = () => {
  const componentRef = useRef();

  const navigate = useNavigate();

  const handleOptionChange = (option) => {
    navigate(option.url);
  };

  return (
    <div className="d-flex flex-row w-100">
      <div>
        <Sidebar handleOptionChange={handleOptionChange} />
      </div>
      <div
        style={{
          marginLeft: "80px",
          paddingRight: "30px",
          marginTop: "50px",
          width: "95%",
        }}
      >
        <ClientComplienceReport />
      </div>
    </div>
  );
};

export default ClientComplienceReportPage;
