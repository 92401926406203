import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";

const AddClient = ({ isOpen, onClose }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const role = useAuth().role;
  const programId = useAuth().programId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [programs, setPrograms] = useState([]);
  const [programName, setProgramName] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [intakeDate, setIntakeDate] = useState("");
  const [programCompletionDate, setProgramCompletionDate] = useState("");
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [notes, setNotes] = useState("");
  const [isExpire, setIsExpire] = useState(false);
  const [dischargeDate, setDischargeDate] = useState("");
  const [dob, setdob] = useState("");

  useEffect(() => {
    getPrograms();
    if (!isOpen) {
      setName("");
      setIntakeDate("");
      setProgramCompletionDate("");
      setEmail("");
      setPhone("");
      setNotes("");
      setDischargeDate("");
      setdob("");
    }
  }, [isOpen]);

  const getPrograms = async () => {
    try {
      const response = await fetch(`${base_url}/program/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      setPrograms(data);
      const newprogram = data.find((program) => program.id == programId);
      setProgramName(newprogram.name);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleAddClick = () => {
    if (name == "" || intakeDate == "" || programCompletionDate == "") {
      toast.warn("Please fill required fields");
      return;
    }

    if (programId === "-1") {
      toast.warn("Please select Valid Program First!");
      return;
    }
    setLoading(true);

    const payload = {
      name: name,
      intakeDate: intakeDate,
      programCompletionDate: programCompletionDate,
      activeStatus: 1,
      email: email,
      phone: phone,
      updatedBy: userId,
      programId: programId,
      created_by: userId,
      notes: notes,
      dischargeDate: dischargeDate,
      dob: dob,
    };

    fetch(`${base_url}/client/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Client added Successful!");
        setName("");
        setEmail("");
        setPhone("");
        setIntakeDate("");
        setProgramCompletionDate("");
        setNotes("");
        setDischargeDate("");
        setdob("");
        onClose();
      })
      .catch((error) => {
        console.error("Error adding Client:", error);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add A Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control "
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="program" className="w-25">
                Program
              </label>
              <input
                type="text"
                id="program"
                className="form-control "
                value={programName}
                // onChange={(e) => setIntakeDate(e.target.value)}
                placeholder="program"
                disabled
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="start" className="w-25">
                Start Date
              </label>
              <input
                type="date"
                id="start"
                className="form-control "
                value={intakeDate}
                onChange={(e) => setIntakeDate(e.target.value)}
                placeholder="Intake Date"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="end" className="w-25">
                End Date
              </label>
              <input
                type="date"
                id="end"
                className="form-control"
                value={programCompletionDate}
                onChange={(e) => setProgramCompletionDate(e.target.value)}
                placeholder="Program Completion Date"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="dob" className="w-25">
                Date of Birth
              </label>
              <input
                type="date"
                id="dob"
                className="form-control "
                value={dob}
                onChange={(e) => setdob(e.target.value)}
                placeholder="Date of Birth"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="email" className="w-25">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email (Optional)"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="phone" className="w-25">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                // maxLength={10}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone (Optional)"
              />
            </div>

            <div className="d-flex align-items-center mb-3">
              <label htmlFor="notes" className="w-25">
                Note
              </label>
              <input
                type="textarea"
                id="notes"
                className="form-control "
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Notes (Optional)"
              />
            </div>

            {role === "Super Admin" || role === "Supervisor" ? (
              <>
                <div className="d-flex align-items-center mb-3">
                  <label htmlFor="DischargeDate" className="w-25">
                    Discharge Date
                  </label>
                  <input
                    type="date"
                    id="DischargeDate"
                    className="form-control"
                    value={dischargeDate}
                    onChange={(e) => setDischargeDate(e.target.value)}
                    placeholder="Discharge Date"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center mb-3">
                  <label htmlFor="name" className="w-25">
                    Discharge Date
                  </label>
                  <input
                    type="date"
                    id="end"
                    className="form-control"
                    value={dischargeDate}
                    onChange={(e) => setDischargeDate(e.target.value)}
                    placeholder="Discharge Date"
                    disabled
                  />
                </div>
              </>
            )}

            {/* <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Discharge Date
              </label>
              <input
                type="date"
                id="end"
                className="form-control"
                value={dischargeDate}
                onChange={(e) => setDischargeDate(e.target.value)}
                placeholder="Discharge Date"
              />
            </div> */}
          </div>
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Add Client
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddClient;
