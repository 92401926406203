import React, { useEffect, useRef, useState } from "react";
import LogoutModal from "../../logoutModal/LogoutModal";
import { useAuth } from "../../../context/useAuth";
import { Typeahead } from "react-bootstrap-typeahead";
import { Piechart } from "../../PieChart";
import DataTable from "react-data-table-component";
import moment from "moment";

const ClientComplienceReport = () => {
  const [isExpire, setIsExpire] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const programId = useAuth().programId;
  const [clients, setSclients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [presentAppointments, setPresentAppointments] = useState([]);
  const [absentAppontments, setAbsentAppointments] = useState([]);
  const [excusedAbsentAppointments, setExcusedAbsentAppointments] = useState(
    []
  );
  const [cancelledAppointments, setCancelledAppointments] = useState([]);
  const [uncheckedAppointments, setUncheckedAppointments] = useState([]);
  const [futureBookings, setFutureBookings] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, SetTotalAbsent] = useState(0);
  const [uncheckedBooking, setUncheckedBooking] = useState(0);
  const [futureBooking, setFutureBooking] = useState(0);
  const [ClientComplianceRate, setClientComplianceRate] = useState(0);
  const [programProgressRate, setProgramProgressRate] = useState(0);
  const [numerator1, setNumerator1] = useState(0);
  const [denominator1, setDenominator1] = useState(0);
  const [numerator2, setNumerator2] = useState(0);
  const [denominator2, setDenominator2] = useState(0);
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [subdepartment, setSubdepartment] = useState("");
  const [department, setDepartment] = useState("");
  const today = new Date(Date.now());

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClient(selectedClient);
  };

  function convertTimeToDate(dateString) {
    // console.log(dateString);
    if (!dateString || dateString === "null") {
      return "";
    }
    const newDate = moment.utc(dateString).format("MM/DD/YYYY");
    return newDate;
  }

  const dateSort = (rowA, rowB) => {
    // console.log(rowA, rowB);
    const dateA = new Date(rowA.date);
    const dateB = new Date(rowB.date);
    return dateA - dateB; // Ascending order
  };

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  const clientsColumns = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "230px",
    },
    {
      name: "Date of Birth",
      selector: (row) => convertTimeToDate(row.dob),
      sortable: true,
      width: "180px",
      // sortFunction: dateSortForDOB,
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "150px",
      // sortFunction: dateSortForIntake,
    },
    {
      name: "SUD DOA",
      selector: (row) => convertTimeToDate(row.sud_doa),
      sortable: true,
      width: "150px",
      // sortFunction: dateSortForSUD_DOA,
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "240px",
      // sortFunction: dateSortForCompliance,
      cell: (row) => (
        <div
          style={{
            color:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "#BB2B3D"
                : "black",
            fontWeight:
              new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
                ? "bold"
                : "",
          }}
        >
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Discharge Date",
      selector: (row) => convertTimeToDate(row.DischargeDate),
      sortable: true,
      width: "170px",
      // sortFunction: dateSortForDischarge,
    },
    {
      name: "Days",
      selector: (row) => row.daysBetweenIntakeAndDischarge,
      sortable: true,
      width: "100px",
    },
    {
      name: "SAIOP AM or PM Group",
      selector: (row) => row.clientAMPMGroup,
      sortable: true,
      width: "230px",
      // sortFunction: ampmSort,
    },
    {
      name: "Active Status",
      selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          style={{
            color: row.ActiveStatus == 1 ? "black" : "#BB2B3D",
            fontWeight: row.ActiveStatus == 1 ? "" : "bold",
          }}
        >
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.Email == "" ? "No Email" : row.Email),
      sortable: true,
      width: "180px",
    },
    {
      name: "Phone",
      selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
      sortable: true,
      width: "180px",
    },
    {
      name: "Updated by",
      selector: (row) => row.updated_by,
      sortable: true,
      width: "170px",
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: true,
      width: "250px",
    },
    // {
    //   name: "Created by",
    //   selector: (row) => row.created_by,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Id",
    //   selector: (row) => row.ClientID,
    //   sortable: true,
    //   width: "5%",
    // },
    // {
    //   name: "Name",
    //   selector: (row) => row.name,
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Intake Date",
    //   selector: (row) => convertTimeToDate(row.IntakeDate),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Program Completion Date",
    //   selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
    //   sortable: true,
    //   width: "10%",
    //   cell: (row) => (
    //     <div
    //       style={{
    //         color:
    //           new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
    //             ? "#BB2B3D"
    //             : "black",
    //         fontWeight:
    //           new Date(row.ProgramCompletionDate).getTime() <= today.getTime()
    //             ? "bold"
    //             : "",
    //       }}
    //     >
    //       {convertTimeToDate(row.ProgramCompletionDate)}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Discharge Date",
    //   selector: (row) => convertTimeToDate(row.discharge_date),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Days",
    //   selector: (row) => row.daysBetweenIntakeAndDischarge,
    //   sortable: true,
    //   width: "6%",
    // },
    // {
    //   name: "Active Status",
    //   selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
    //   sortable: true,
    //   width: "10%",
    //   cell: (row) => (
    //     <div
    //       style={{
    //         color: row.ActiveStatus == 1 ? "black" : "#BB2B3D",
    //         fontWeight: row.ActiveStatus == 1 ? "" : "bold",
    //       }}
    //     >
    //       {row.ActiveStatus == 1 ? "Active" : "Inactive"}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Email",
    //   selector: (row) => (row.Email == "" ? "No Email" : row.Email),
    //   sortable: true,
    //   width: "14%",
    // },
    // {
    //   name: "Phone",
    //   selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Updated by",
    //   selector: (row) => row.updated_by,
    //   sortable: true,
    //   width: "15%",
    // },
  ];

  const appointmentColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "5%",
    },
    {
      name: "Client",
      selector: (row) => row.client_name,
      sortable: true,
      width: "18%",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "10%",
    },
    {
      name: "Sub Department",
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "13%",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.date),
      sortable: true,
      width: "11%",
      // sortFunction: dateSort,
    },
    {
      name: "Time",
      selector: (row) =>
        `${convertTimeToHHMM(row.start_time)} - ${convertTimeToHHMM(
          row.end_time
        )}`,
      sortable: true,
      width: "12%",
    },
    {
      name: "Attendance",
      selector: (row) =>
        `${
          row.attendance === 1
            ? row.excused_absence_comment === null
              ? "Marked as Present"
              : "Excused Absence"
            : row.attendance === 0
            ? "Marked as Absent"
            : row.attendance === 2
            ? "Cancelled"
            : new Date(row.date) <= new Date()
            ? "Unchecked"
            : "Future Booking"
        }`,
      sortable: true,
      width: "11%",
    },
    {
      name: "Updated By",
      selector: (row) => row.username,
      sortable: true,
      width: "20%",
    },
  ];

  const customStylesForClient = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px",
        color: "black",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px",
        color: "black",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const differentDays = (date1, date2, date3) => {
    let d2;

    if (date2 === "null" || date2 === null) {
      d2 = null;
    } else {
      d2 = new Date(date2);
    }

    const d1 = new Date(date1);
    // const d2 = new Date(date2);
    const d3 = new Date(date3);

    if (d2 === null) {
      let oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(d1 - d3) / oneDay);
    } else {
      let oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(d1 - d2) / oneDay);
    }
  };

  const handleClient = async (value) => {
    if (value.length === 0) {
      setClientComplianceRate(0);
      setProgramProgressRate(0);
      setNumerator1(0);
      setDenominator1(0);
      setNumerator2(0);
      setDenominator2(0);
      setTotalAppointments(0);
      setTotalPresent(0);
      SetTotalAbsent(0);
      setAbsentAppointments([]);
      setPresentAppointments([]);
      setCancelledAppointments([]);
      setExcusedAbsentAppointments([]);
      setUncheckedAppointments([]);
      setFutureBookings([]);
      setClientDetails([]);
      setUncheckedBooking(0);
      setFutureBooking(0);
      return;
    }

    const apiUrl = `${base_url}/appointment/compliance/getByClient`;

    console.log(`client: `, selectedClient[0]);

    console.log(`value: `, value[0]);

    // return;
    const clientData = clients.find(
      (client) => client.split("&")[0] == value[0].split("&")[0]
    );

    console.log("clientData: ", clientData);

    let da = differentDays(clientData.split("&")[2], clientData.split("&")[8]);

    // console.log(`da: `, da);

    const obj = [
      {
        ClientID: clientData.split("&")[0],
        name: clientData.split("&")[1],
        IntakeDate: clientData.split("&")[2],
        ProgramCompletionDate: clientData.split("&")[3],
        ActiveStatus: clientData.split("&")[4],
        Email: clientData.split(":&")[5],
        Phone: clientData.split("&")[6],
        updated_by: clientData.split("&")[7],
        discharge_date: clientData.split("&")[8],
        daysBetweenIntakeAndDischarge: await differentDays(
          clientData.split("&")[2],
          clientData.split("&")[8],
          clientData.split("&")[3]
        ),
      },
    ];

    // console.log(`obj: `, obj);

    setClientDetails(obj);

    let payload = {
      clientID: clientData.split("&")[0],
      programId: programId,
      departmentId: department,
      subDepartmentId: subdepartment,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          setIsExpire(true);
          throw new Error("Network response was not ok");
        }
        let result = await response.json();

        let temp = [];
        let totalNull = 0;
        let PresentSum = 0;
        let totalUnchecked = 0;
        let futureUnchecked = 0;
        let totalAbsent = 0;
        let cancel = 0;

        let presentApp = [];
        let absentApp = [];
        let cancelledApp = [];
        let excusedApp = [];
        let uncheckedApp = [];
        let futureApp = [];

        for (let i = 0; i < result.length; i++) {
          if (result[i]["attendance"] === 0) {
            absentApp.push(result[i]);
            totalAbsent = totalAbsent + 1;
          }

          if (result[i]["attendance"] === null) {
            // uncheckedApp.push(result[i]);
            totalNull = totalNull + 1;
          }

          if (
            new Date(result[i]["date"]) <= new Date() &&
            result[i]["attendance"] == null
          ) {
            uncheckedApp.push(result[i]);
            totalUnchecked = totalUnchecked + 1;
          }

          if (new Date(result[i]["date"]) > new Date()) {
            futureApp.push(result[i]);
            futureUnchecked = futureUnchecked + 1;
          }

          if (result[i]["attendance"] === 1) {
            if (result[i]["excused_absence_comment"] === null) {
              presentApp.push(result[i]);
            } else {
              excusedApp.push(result[i]);
            }
            PresentSum = PresentSum + result[i]["attendance"];
          }

          if (result[i]["attendance"] === 2) {
            cancelledApp.push(result[i]);
            cancel = cancel + 1;
          }

          temp.push({
            id: result[i]["id"],
            client_name: result[i]["client_name"],
            department_name: result[i]["department_name"],
            sub_department_name: result[i]["sub_department_name"],
            start_time: result[i]["start_time"],
            end_time: result[i]["end_time"],
            attendance: result[i]["attendance"],
            excused_absence_comment: result[i]["excused_absence_comment"],
            date: result[i]["date"],
            user_name: result[i]["username"],
            updated_at: result[i]["updated_at"],
            notes: result[i]["notes"],
          });
        }

        setAbsentAppointments(absentApp);
        setPresentAppointments(presentApp);
        setCancelledAppointments(cancelledApp);
        setExcusedAbsentAppointments(excusedApp);
        setUncheckedAppointments(uncheckedApp);
        setFutureBookings(futureApp);

        let length = temp.length;
        setTotalAppointments(length);
        // if (PresentSum + totalAbsent == 0) {
        // setTotalPresent(0);
        // } else {
        setTotalPresent(PresentSum);
        // }
        SetTotalAbsent(totalAbsent);
        setUncheckedBooking(totalUnchecked);
        setFutureBooking(futureUnchecked);
        var Total =
          parseInt(PresentSum) / parseInt(length - totalNull - cancel);
        var total2 = parseInt(length - totalNull) / parseInt(length);

        if (isNaN(Total)) {
          Total = 0;
        }
        if (isNaN(total2)) {
          total2 = 0;
        }

        // console.log(`Total: ${Total}`);
        // console.log(`total2: ${total2}`);
        if (length == 0) {
          setClientComplianceRate(0);
          setProgramProgressRate(0);
          setNumerator1(0);
          setDenominator1(0);
          setNumerator2(0);
          setDenominator2(0);
        } else {
          if (parseInt(length - totalNull) == 0) {
            setClientComplianceRate(0);
          } else {
            setClientComplianceRate(Total * 100);
            setProgramProgressRate(total2 * 100);
            setNumerator1(parseInt(PresentSum));
            if (length - totalNull == 0) {
              setDenominator1(0);
            } else {
              setDenominator1(parseInt(length - totalNull));
            }

            if (parseInt(length - totalNull) == 0) {
              setNumerator2(0);
            } else {
              setNumerator2(parseInt(length - totalNull));
            }
            setDenominator2(parseInt(length));
          }
        }
      })
      .then((data) => {})

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {});
  };

  const handleTypeaheadChange = (selected) => {
    // console.log(`selected: `, selected);
    setSelectedClient(selected);
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      // console.log("data", data);
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]}&${data[i]["client_name"]}&${data[i]["program_intake_date"]}&${data[i]["program_completion_date"]}&${data[i]["status"]}&${data[i]["email"]}&${data[i]["phone"]}&${data[i]["username"]}&${data[i]["discharge_date"]}`
          );
        }
        resolve(temp);
      });
      setSclients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setDepartments(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const getSubdepartments = async (val) => {
    try {
      const response = await fetch(`${base_url}/subdepartment/deptId/${val}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`temp : `, data);
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]}:${data[i]["name"]}:${data[i]["slot_duration"]}`
          );
        }
        resolve(temp);
      });
      setSubdepartments(temp);
    } catch (error) {
      console.error("Error fetching subdepartments:", error);
    }
  };

  useEffect(() => {
    getClients();
    getDepartments();
  }, []);

  const componentRef = useRef();
  const handlePrint = () => {
    // window.print();
    const printContent = componentRef.current;
    const originalContents = document.body.innerHTML;

    // Replace body content with our component content
    document.body.innerHTML = printContent.innerHTML;

    // Print
    window.print();

    // Restore original content
    document.body.innerHTML = originalContents;
  };

  return (
    <div className="" ref={componentRef}>
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4 ">Client Compliance Report</h3>
      <hr />
      {/* <button onClick={handlePrint}>Print</button> */}
      <form onSubmit={handleSubmit} className="">
        <div className=" ">
          <div className="d-flex flex-row gap-3 mt-2  mb-3">
            <div className="w-25">
              <Typeahead
                className=""
                id="client"
                labelKey={(option) => `${option.split("&")[1]}`}
                onChange={handleTypeaheadChange}
                options={clients.map((elem) => elem)}
                selected={selectedClient}
                placeholder="Select a Client"
              />
            </div>

            <div className="w-25">
              <select
                className="form-select"
                id="department"
                aria-label="Default select example"
                defaultValue=""
                onChange={async (e) => {
                  if (e.target.value !== "") {
                    await getSubdepartments(e.target.value);
                  } else {
                    setSubdepartments([]);
                  }
                  setDepartment(e.target.value);
                }}
              >
                <option value="">Select a Department</option>
                {Departments.map((option, index) => (
                  <option key={index} value={option.split(":")[0]}>
                    {option.split(":")[1]}
                  </option>
                ))}
              </select>
            </div>

            <div className=" w-25">
              <select
                className="form-select "
                id="subdepartment"
                aria-label="Default select example"
                onChange={async (e) => {
                  setSubdepartment(e.target.value);
                }}
              >
                <option selected>Select a Sub Department</option>
                {Subdepartments.map((option, index) => (
                  <option key={index} value={option.split(":")[0]}>
                    {option.split(":")[1]}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="Submit"
              className="btn btn-primary "
              style={{ width: "100px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      {clientDetails.length > 0 && (
        <>
          <div className="mb-4">
            <h5 className="text-center mb-2">Client Details</h5>
            <DataTable
              columns={clientsColumns}
              data={clientDetails}
              pagination
              customStyles={customStylesForClient}
              highlightOnHover
            />
            <hr />
          </div>
        </>
      )}

      <div className="mt-4">
        <div className="col-xl-6 mb-4 w-100 d-flex justify-content-between">
          <div className="card card-header-actions" style={{ width: "30%" }}>
            <div className="card-header fw-bold">Compliance Rate</div>
            <div className="card-body">
              <div style={{ height: "300px" }} className="chart-area px-3">
                <Piechart
                  Rate={ClientComplianceRate}
                  Present={"Total Compliance"}
                  Absent={"Absence"}
                  Numerator={numerator1}
                  Denominator={denominator1}
                  isCompliance={true}
                />
              </div>
            </div>
          </div>
          <div className="card card-header-actions " style={{ width: "30%" }}>
            <div className="card-header fw-bold">Appointment Stats</div>
            <div className="card-body">
              <div style={{ height: "310px" }} className="chart-area">
                <div className="card">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Appointments Booked</span>
                      <span className="text-end">{totalAppointments}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Present</span>
                      <span className="text-end">{totalPresent}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Absent</span>
                      <span className="text-end">{totalAbsent}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Future Appointments</span>
                      <span className="text-end">{futureBooking}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Unchecked</span>
                      <span className="text-end">{uncheckedBooking}</span>
                    </li>
                  </ul>
                </div>
                <div className="fs-1 fw-bolder text-center d-flex mt-3 align-items-center justify-content-center">
                  <div className=" lh-1">
                    <p className="">
                      {totalPresent} / {totalAbsent + totalPresent}
                    </p>
                    <p className="" style={{ fontSize: "15px" }}>
                      Current Appointment Success Rate
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-header-actions  " style={{ width: "30%" }}>
            <div className="card-header fw-bold">Program Progress Rate</div>
            <div className="card-body">
              <div style={{ height: "300px" }} className="chart-area px-2">
                <Piechart
                  Rate={programProgressRate}
                  Present={"Completed Appointments"}
                  Absent={"Total Appointments"}
                  Numerator={numerator2}
                  Denominator={denominator2}
                  isCompliance={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        {presentAppointments.length > 0 && (
          <div className="mb-4">
            <h5 className="text-center mb-2">
              Present Appointments({presentAppointments.length})
            </h5>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              columns={appointmentColumns}
              data={presentAppointments}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
            <hr />
          </div>
        )}
        {absentAppontments.length > 0 && (
          <div className="mb-4">
            <h5 className="text-center mb-2">
              Absent Appointments({absentAppontments.length})
            </h5>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              columns={appointmentColumns}
              data={absentAppontments}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
            <hr />
          </div>
        )}
        {excusedAbsentAppointments.length > 0 && (
          <div className="mb-4">
            <h5 className="text-center mb-2">
              Excused Absent Appointments({excusedAbsentAppointments.length})
            </h5>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              columns={appointmentColumns}
              data={excusedAbsentAppointments}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
            <hr />
          </div>
        )}
        {cancelledAppointments.length > 0 && (
          <div className="mb-4">
            <h5 className="text-center mb-2">
              Cancelled Appointments({cancelledAppointments.length})
            </h5>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              columns={appointmentColumns}
              data={cancelledAppointments}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
            <hr />
          </div>
        )}
        {uncheckedAppointments.length > 0 && (
          <div className="mb-4">
            <h5 className="text-center mb-2">
              Unchecked Appointments({uncheckedAppointments.length})
            </h5>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              columns={appointmentColumns}
              data={uncheckedAppointments}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
          </div>
        )}
        {futureBookings.length > 0 && (
          <div className="mb-4">
            <h5 className="text-center mb-2">
              Future Appointments({futureBookings.length})
            </h5>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              columns={appointmentColumns}
              data={futureBookings}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientComplienceReport;
