import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import Clients from "../components/optionScreens/addClient/Clients";

const ClientsPage = () => {
  const navigate = useNavigate();
  const handleOptionChange = (option) => {
    navigate(option.url);
  };

  return (
    <>
      <>
        <div className="d-flex flex-row w-100">
          <div>
            <Sidebar handleOptionChange={handleOptionChange} />
          </div>
          <div
            style={{
              marginLeft: "80px",
              paddingRight: "30px",
              marginTop: "50px",
              width: "95%",
            }}
          >
            <Clients />
          </div>
        </div>
      </>
    </>
  );
};

export default ClientsPage;
