import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import AddASubDepartment from "./AddASubDepartment";
import UpdateSubDepartment from "./UpdateSubDepartment";
import LogoutModal from "../../logoutModal/LogoutModal";
import DataTable from "react-data-table-component";

const SubDepartment = () => {
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isSubDepModalOpen, setIsSubDepModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "Department Name",
      selector: (row) => `${row.split(":")[1]}`,
      sortable: true,
    },
    {
      name: "Sub Department Name",
      selector: (row) =>
        `${row.split(":")[2] == "null" ? "" : `${row.split(":")[2]}`}`,
      sortable: true,
    },
    {
      name: "Time Slot",
      selector: (row) =>
        `${row.split(":")[3] == "null" ? "" : `${row.split(":")[3]}`}`,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.split(":")[5])}
        >
          Action
        </button>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px",
        color: "black",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const getDepartmentSubdepartment = async () => {
    try {
      const response = await fetch(`${base_url}/department/getDepSubDep`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`data : `, data)
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["departmentId"]}:${data[i]["departmentName"]}:${data[i]["subDepartmentName"]}:${data[i]["timeSlot"]}:${data[i]["subdepartmentId"]}:${i}:${data[i]["sub_department_color"]}`
          );
        }
        resolve(temp);
      });
      setTableData(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleSubDepartmentButtonClick = () => {
    setIsSubDepModalOpen(true);
  };

  const closeSubDepModal = async () => {
    setIsSubDepModalOpen(false);
    await getDepartmentSubdepartment();
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getDepartmentSubdepartment();
  };

  const handleActionClick = (e) => {
    const newData = tableData.filter((value) => {
      return value.split(":")[5] === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  useEffect(() => {
    getDepartmentSubdepartment();
  }, [isSubDepModalOpen]);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Sub Departments</h3>
      <hr />

      <div className="row mt-1 ms-1 mb-3">
        <button
          className="btn btn-primary"
          type="button"
          style={{ width: "210px" }}
          onClick={handleSubDepartmentButtonClick}
        >
          Add A Sub Department
        </button>
      </div>

      <AddASubDepartment
        isOpen={isSubDepModalOpen}
        onClose={closeSubDepModal}
      />
      {isActionModalopen && (
        <UpdateSubDepartment
          isOpen={isActionModalopen}
          onClose={closeActionModal}
          data={actionModalData}
        />
      )}
      <DataTable
        columns={columns}
        data={tableData}
        customStyles={customStyles}
        highlightOnHover
        pagination
      />
    </div>
  );
};

export default SubDepartment;
