import React, { useState } from "react";
import FilterForm from "./FilterForm";
import FilterTable from "./FilterTable";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";
import { ScaleLoader } from "react-spinners";
import { CSVDownload } from "react-csv";
import moment from "moment";

const Reports = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const [download, setDownload] = useState(false);
  const [visible, setVisible] = useState(false);
  const [report, setReport] = useState([]);
  const [obj, setObj] = useState({});
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);
  const programId = useAuth().programId;
  const [loading, setLoading] = useState(false);
  var [csvData, setCsvData] = useState(dataForCSV);

  function toggleVisibility(dept, subdept, client) {
    submitBehaviour(
      dept === -1 ? undefined : obj["deptID"],
      subdept === -1 ? undefined : obj["subdeptID"],
      client === -1 ? -1 : obj["clientID"],
      obj["startDate"],
      obj["endDate"]
    );
  }

  function fetcher(data) {
    setObj(data);
  }

  var dataForCSV = [
    [
      "Appointment ID",
      "Client",
      "Department",
      "Sub-department",
      "Start Time",
      "End Time",
      "Attendance",
      "Date",
      "Excused Absence Reason",
      "Updated by",
      "Last Updated",
    ],
  ];

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  function convertTimeToDate(dateString) {
    const newDate = moment(dateString, "YYYY-MM-DD").format("MMM Do YYYY");
    return newDate;
  }

  function submitBehaviour(
    deptID,
    subdeptID,
    clientID,
    startDate,
    endDate,
    attendance,
    excusedAbsence,
    userId,
    isUnchecked,
    isCancelled
  ) {
    setLoading(true);
    var availability = {
      deptID: 1,
      subdeptID: 1,
      clientID: clientID == -1 ? -1 : 1,
      startDate: 1,
      endDate: 1,
    };
    if (deptID === "") {
      deptID = -1;
      availability["deptID"] = -1;
    }
    if (subdeptID === "") {
      subdeptID = -1;
      availability["subdeptID"] = -1;
    }
    const payload = {
      departmentID: deptID,
      subDepartmentID: subdeptID,
      clientID: clientID,
      startDate: startDate,
      endDate: endDate,
      attendance: attendance,
      excusedAbsence: excusedAbsence,
      userId: userId,
      programId: programId,
      isUnchecked: isUnchecked,
      isCancelled: isCancelled,
    };

    fetch(`${base_url}/appointment/report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        let data = await response.json();

        // console.log(`data : `, data);

        data.message.sort((a, b) => new Date(a.date) - new Date(b.date));

        for (let i = 0; i < data["message"].length; i++) {
          dataForCSV.push([
            data["message"][i]["id"],
            data["message"][i]["client_name"],
            data["message"][i]["department_name"],
            data["message"][i]["sub_department_name"],
            convertTimeToHHMM(data["message"][i]["start_time"]),
            convertTimeToHHMM(data["message"][i]["end_time"]),
            data["message"][i]["attendance"] === 1
              ? data["message"][i]["excused_absence_comment"] === null
                ? "Present"
                : "Excused Absence"
              : data["message"][i]["attendance"] === 0
              ? "Absent"
              : data["message"][i]["attendance"] === 2
              ? "Cancelled"
              : new Date(data["message"][i]["date"]) <= new Date()
              ? "Unchecked"
              : "Future Booking",
            convertTimeToDate(data["message"][i]["date"]),
            data["message"][i]["excused_absence_comment"],
            data["message"][i]["username"],
            data["message"][i]["updated_at"],
          ]);
        }

        setCsvData(dataForCSV);

        setReport([data["message"], availability, true]);
      })
      .catch((error) => {
        console.error("Error adding Client:", error);
      })
      .finally(() => {
        setLoading(false);
        setVisible(true);
      });
  }

  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  return (
    <>
      <div className="">
        {isExpire && (
          <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
        )}
        <FilterForm onSubmit={submitBehaviour} fetchData={fetcher} />
        <br />
        {loading && <ScaleLoader />}
        {visible ? (
          <>
            <button
              type="button"
              className="btn btn-success"
              onClick={downloadCSV}
            >
              Download CSV
            </button>

            {download ? <CSVDownload data={csvData} target="_blank" /> : <></>}
            <FilterTable toggleVisibility={toggleVisibility} report={report} />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Reports;
