import React from "react";
import Markdown from "react-markdown";

const Message = ({ message }) => {
  return (
    <div
      className={`message ${
        message.sender === "user" ? "user-message" : "bot-message"
      }`}
    >
      <div className="message-content">
        <Markdown>{message.text}</Markdown>
        {/* <p>{message.text}</p> */}
        {/* <span className="timestamp">{message.timestamp}</span> */}
      </div>
    </div>
  );
};

export default Message;
