import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";

const AddProgram = ({ Subdepartments, isOpen, onClose }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState("");
  const [isExpire, setIsExpire] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setName("");
    }
  }, [isOpen]);

  const handleAddClick = () => {
    if (name == "") {
      toast.warn("Please fill required fields");
      return;
    }
    const payload = {
      name: name,
      updatedBy: userId,
      values: values,
    };

    fetch(`${base_url}/program/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Program added Successful!");
        setName("");
        onClose();
      })
      .catch((error) => {
        console.error("Error adding Program:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  useEffect(() => {
    setValues(
      Subdepartments.map((item) => ({
        subDepartmentName: item.split(":")[0], // Extract name
        frequencyType: "one-time", // Default selection
        frequencyValue: 0, // Default number input
      }))
    );
  }, [Subdepartments]);

  const handleDropdownChange = (index, selectedValue) => {
    const updatedValues = [...values];
    updatedValues[index].frequencyType = selectedValue;
    setValues(updatedValues);
  };

  const handleNumberChange = (index, newValue) => {
    const updatedValues = [...values];
    updatedValues[index].frequencyValue = newValue;
    setValues(updatedValues);
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add A Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control "
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            {Subdepartments &&
              Subdepartments.length > 0 &&
              Subdepartments.map((item, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center gap-3 mb-3"
                >
                  <label htmlFor={`name-${index}`} className="w-25">
                    {item.split(":")[1]}
                  </label>

                  <select
                    id={`dropdown-${index}`}
                    className="form-select w-50"
                    value={values[index]?.frequencyType || "one-time"}
                    onChange={(e) =>
                      handleDropdownChange(index, e.target.value)
                    }
                  >
                    <option value="one-time">One Time</option>
                    <option value="weekly">Weekly</option>
                    <option value="custom">Custom</option>
                    <option value="biweekly">Biweekly</option>
                    <option value="monthly">Monthly</option>
                  </select>

                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    style={{ width: "15%" }}
                    value={values[index]?.frequencyValue || 0}
                    onChange={(e) =>
                      handleNumberChange(index, Number(e.target.value))
                    }
                  />
                </div>
              ))}
          </div>
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Add Program
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProgram;
