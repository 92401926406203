import React, { useEffect, useState } from "react";
import LogoutModal from "../../logoutModal/LogoutModal";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAuth } from "../../../context/useAuth";
import Button from "react-bootstrap/esm/Button";
import AppointmentModel from "./AppointmentModel";
import DataTable from "react-data-table-component";
import moment from "moment";
import { toast } from "react-toastify";

const ClientComplianceTable = () => {
  const [isExpire, setIsExpire] = useState(false);
  const programId = useAuth().programId;
  const token = useAuth().token;
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [client, setClient] = useState("");
  const [actAppData, setActAppData] = useState([]);
  const [expAppData, setExpActAppData] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [individualAppointmentData, setIndividualAppointmentData] = useState(
    []
  );
  const [clientDetails, setClientDetails] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState("");
  const today = new Date(Date.now());

  const [isCheckedFrequency, setIsCheckFrequency] = useState(false);
  const [isCheckedOccurrence, setIsCheckOccurrence] = useState(false);

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
  };

  const handleOpenEvent = () => {
    setIsOpenModel(false);
  };

  const differentDays = (date1, date2, date3) => {
    let d2;

    if (date2 === "null" || date2 === null) {
      d2 = null;
    } else {
      d2 = new Date(date2);
    }

    const d1 = new Date(date1);
    // const d2 = new Date(date2);
    const d3 = new Date(date3);

    if (d2 === null) {
      let oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(d1 - d3) / oneDay);
    } else {
      let oneDay = 24 * 60 * 60 * 1000;
      return Math.round(Math.abs(d1 - d2) / oneDay);
    }
  };

  const clientsColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.client_name,
      sortable: true,
      width: "230px",
    },
    {
      name: "Date of Birth",
      selector: (row) => convertTimeToDate(row.dob),
      sortable: true,
      width: "180px",
      // sortFunction: dateSortForDOB,
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.program_intake_date),
      sortable: true,
      width: "150px",
      // sortFunction: dateSortForIntake,
    },
    {
      name: "SUD DOA",
      selector: (row) => convertTimeToDate(row.sud_doa),
      sortable: true,
      width: "150px",
      // sortFunction: dateSortForSUD_DOA,
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.program_completion_date),
      sortable: true,
      width: "240px",
      // sortFunction: dateSortForCompliance,
      cell: (row) => (
        <div
          style={{
            color:
              new Date(row.program_completion_date).getTime() <= today.getTime()
                ? "#BB2B3D"
                : "black",
            fontWeight:
              new Date(row.program_completion_date).getTime() <= today.getTime()
                ? "bold"
                : "",
          }}
        >
          {convertTimeToDate(row.program_completion_date)}
        </div>
      ),
    },
    {
      name: "Discharge Date",
      selector: (row) => convertTimeToDate(row.discharge_date),
      sortable: true,
      width: "170px",
      // sortFunction: dateSortForDischarge,
    },
    {
      name: "Days",
      // selector: (row) => row.daysBetweenIntakeAndDischarge,
      selector: (row) =>
        differentDays(
          row.program_intake_date,
          row.discharge_date,
          row.program_completion_date
        ),
      sortable: true,
      width: "100px",
    },
    {
      name: "SAIOP AM or PM Group",
      selector: (row) => row.clientAMPMGroup,
      sortable: true,
      width: "230px",
      // sortFunction: ampmSort,
    },
    {
      name: "Active Status",
      selector: (row) => (row.status == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          style={{
            color: row.status == 1 ? "black" : "#BB2B3D",
            fontWeight: row.status == 1 ? "" : "bold",
          }}
        >
          {row.status == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.email == "" ? "No Email" : row.email),
      sortable: true,
      width: "180px",
    },
    {
      name: "Phone",
      selector: (row) => (row.phone == "" ? "No Phone" : row.phone),
      sortable: true,
      width: "180px",
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: true,
      width: "250px",
    },
    {
      name: "Created by",
      selector: (row) => row.username,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   width: "5%",
    // },
    // {
    //   name: "Name",
    //   selector: (row) => row.client_name,
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Intake Date",
    //   selector: (row) => convertTimeToDate(row.program_intake_date),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Program Completion Date",
    //   selector: (row) => convertTimeToDate(row.program_completion_date),
    //   sortable: true,
    //   width: "15%",
    //   cell: (row) => (
    //     <div
    //       style={{
    //         color:
    //           new Date(row.program_completion_date).getTime() <= today.getTime()
    //             ? "#BB2B3D"
    //             : "black",
    //         fontWeight:
    //           new Date(row.program_completion_date).getTime() <= today.getTime()
    //             ? "bold"
    //             : "",
    //       }}
    //     >
    //       {convertTimeToDate(row.program_completion_date)}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Discharge Date",
    //   selector: (row) => convertTimeToDate(row.discharge_date),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Days",
    //   selector: (row) => {
    //     if (row.discharge_date === null || row.discharge_date === "") {
    //       return differentDays(
    //         row.program_completion_date,
    //         row.program_intake_date
    //       );
    //     } else {
    //       return differentDays(row.discharge_date, row.program_intake_date);
    //     }
    //   },
    //   sortable: true,
    //   width: "6%",
    // },
    // {
    //   name: "Active Status",
    //   selector: (row) => (row.status == 1 ? "Active" : "Inactive"),
    //   sortable: true,
    //   width: "10%",
    //   cell: (row) => (
    //     <div
    //       style={{
    //         color: row.status == 1 ? "black" : "#BB2B3D",
    //         fontWeight: row.status == 1 ? "" : "bold",
    //       }}
    //     >
    //       {row.status == 1 ? "Active" : "Inactive"}
    //     </div>
    //   ),
    // },
    // {
    //   name: "Email",
    //   selector: (row) => (row.email == "" ? "No Email" : row.email),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Phone",
    //   selector: (row) => (row.phone == "" ? "No Phone" : row.phone),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "Updated by",
    //   selector: (row) => row.username,
    //   sortable: true,
    //   width: "15%",
    // },
  ];

  function convertTimeToDate(dateString) {
    // console.log(dateString);
    if (!dateString || dateString === "null") {
      return "";
    }
    const newDate = moment.utc(dateString).format("MMM Do YYYY");
    return newDate;
  }

  const customStylesForClient = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px",
        color: "black",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      let temp = [];
      await new Promise((resolve, reject) => {
        for (let i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
        }
        resolve(temp);
      });

      // console.log(`temp : `, temp);

      setClients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const getClientDetail = async (clientID) => {
    try {
      // const payload = {};

      const response = await fetch(`${base_url}/client/getById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ clientID: clientID }),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      console.log(`data : `, data);
      setClientDetails(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (programId === "-1") {
      toast.warn("Change All program to a specific program!!");
      return;
    }
    setLoading(true);

    // console.log(`client : `, client[0].split(":")[0]);
    // console.log(`cliets: `, clients);

    let clientID = client[0].split(":")[0];

    // return;
    // let clientID = await new Promise((resolve, reject) => {
    //   clients.forEach((elem) => {
    //     if (elem.includes(client[0])) {
    //       resolve(elem.split(":")[0]);
    //     }
    //   });
    //   resolve(-1);
    // });

    setSelectedClientId(clientID);

    await getClientDetail(clientID);

    const apiUrl = `${base_url}/appointment/client/complianceReport`;
    var payload = {
      clientID: clientID,
      programId: programId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let result = await response.json();

        // console.log(`result : `, result);

        let actapp = [];

        let expapp = [];

        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 7)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 5)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 18)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 17)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 6)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 22)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 8)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 12)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 13)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 16)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 15)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 14)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 20)[0]
            ?.Actual_Bookings || 0
        );
        actapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 19)[0]
            ?.Actual_Bookings || 0
        );
        setActAppData(actapp);
        // console.log(`app: `, actapp);

        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 7)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 5)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 18)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 17)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 6)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 22)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 8)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 12)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 13)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 16)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 15)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 14)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 20)[0]
            ?.expected_count || 0
        );
        expapp.push(
          result.filter((elem) => elem["SubdepartmentId"] == 19)[0]
            ?.expected_count || 0
        );

        setExpActAppData(expapp);
        setLoading(false);
      })
      .then((data) => {
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAppointmentsByClientDepartmentSubDepartment = async (
    deptId,
    subDeptId
  ) => {
    setIsOpenModel(true);

    try {
      const payload = {
        departmentID: deptId,
        subDepartmentID: subDeptId,
        clientID: selectedClientId,
        startDate: "1970-01-01",
        endDate: "2070-01-01",
        attendance: false,
        excusedAbsence: false,
        userId: -1,
        programId: programId,
        isUnchecked: false,
        isCancelled: false,
      };

      const response = await fetch(`${base_url}/appointment/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      console.log(`data : `, data.message);
      setIndividualAppointmentData(data.message);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  // const handleAppointment = async (appointmentId) => {
  //   setIsOpenModel(true);
  //   try {
  //     const payload = {
  //       programId: programId,
  //       appointmentId: appointmentId,
  //     };

  //     const response = await fetch(`${base_url}/appointment/getbyId`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(payload),
  //     });
  //     if (!response.ok) {
  //       setIsExpire(true);
  //       return;
  //     }
  //     const data = await response.json();
  //     setIndividualAppointmentData(data);
  //   } catch (error) {
  //     console.error("Error fetching clients:", error);
  //   }
  // };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Staff Auditing</h3>
      <hr />

      <div className=" d-flex">
        <form onSubmit={handleSubmit} className="w-50">
          <div className="row mt-2 w-75 mb-3">
            <Typeahead
              className="w-75"
              id="client"
              labelKey={(option) => `${option.split(":")[1]}`}
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem)}
              selected={client}
              placeholder="Select a Client"
            />
            {loading ? (
              <div className="" role="status">
                <span className="">Loading...</span>
              </div>
            ) : (
              <button
                type="Submit"
                className="btn btn-primary "
                style={{ width: "100px" }}
              >
                Submit
              </button>
            )}
          </div>
        </form>

        <div className="d-flex gap-4 w-50">
          <div className="d-flex gap-1 align-items-center">
            <input
              id="freq"
              type="checkbox"
              value={isCheckedFrequency}
              onChange={() => setIsCheckFrequency(!isCheckedFrequency)}
            />
            <label id="freq">Check For Appointment Frequency</label>
          </div>

          <div className="d-flex gap-1 align-items-center">
            <input
              id="occur"
              type="checkbox"
              value={isCheckedOccurrence}
              onChange={() => setIsCheckOccurrence(!isCheckedOccurrence)}
            />
            <label id="occur">Check For Time of Occurrence</label>
          </div>
        </div>
      </div>

      {clientDetails.length > 0 && (
        <>
          <div className="mb-4">
            <h5 className="text-center mb-2">Client Details</h5>
            <DataTable
              columns={clientsColumns}
              data={clientDetails}
              pagination
              customStyles={customStylesForClient}
              highlightOnHover
            />
            <hr />
          </div>
        </>
      )}

      <table class="table table-bordered ">
        <thead>
          <tr>
            {isCheckedFrequency && (
              <th className="" style={{ width: "20%" }}>
                Appointment Frequency
              </th>
            )}

            {isCheckedOccurrence && (
              <th className="" style={{ width: "20%" }}>
                Time of Occurrence
              </th>
            )}

            <th className="" style={{ width: "15%" }}>
              Department
            </th>
            <th className="" style={{ width: "15%" }}>
              Subdepartment
            </th>

            <th className="" style={{ width: "15%" }}>
              Actual Bookings
            </th>
            <th className="" style={{ width: "15%" }}>
              Expected Bookings
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>day 1</td>}
            <td rowspan="5">CVPP</td>
            <td>Medical: Intake</td>

            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(4, 7)
              }
              style={{
                backgroundColor:
                  actAppData[0] != expAppData[0] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[0]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[0] != expAppData[0] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[0]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>day 1</td>}

            <td>Psychiatry: Intake</td>

            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(4, 5)
              }
              style={{
                backgroundColor:
                  actAppData[1] != expAppData[1] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[1]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[1] != expAppData[1] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[1]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>1x/month per client</td>}

            {isCheckedOccurrence && <td>day 30</td>}

            <td>Medical Follow Up</td>

            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(4, 18)
              }
              style={{
                backgroundColor:
                  actAppData[2] != expAppData[2] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[2]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[2] != expAppData[2] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[2]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>2x/month per client</td>}

            {isCheckedOccurrence && (
              <td>day 15, day 30, day 45, day 60, day 75, day 90</td>
            )}

            <td>Psychiatry Follow Up</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(4, 17)
              }
              style={{
                backgroundColor:
                  actAppData[3] != expAppData[3] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[3]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[3] != expAppData[3] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[3]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>1x/week per client</td>}

            {isCheckedOccurrence && (
              <td>
                one every 7 days from start date until completion date (day 90)
              </td>
            )}

            <td>Therapy</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(4, 6)
              }
              style={{
                backgroundColor:
                  actAppData[4] != expAppData[4] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[4]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[4] != expAppData[4] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[4]}
            </td>
          </tr>

          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>day 1</td>}

            <td rowspan="2">SUD</td>
            <td>Intake</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(5, 22)
              }
              style={{
                backgroundColor:
                  actAppData[5] != expAppData[5] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[5]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[5] != expAppData[5] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[5]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>4x/week per client</td>}

            {isCheckedOccurrence && (
              <td>Monday-Thursday from day 2 to day 90</td>
            )}

            <td>Groups</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(5, 8)
              }
              style={{
                backgroundColor:
                  actAppData[6] != expAppData[6] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[6]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[6] != expAppData[6] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[6]}
            </td>
          </tr>

          <tr>
            {isCheckedFrequency && <td>4x/week per client</td>}

            {isCheckedOccurrence && (
              <td>Monday-Thursday from day 2 to day 90</td>
            )}

            <td rowspan="2">AOG</td>
            <td>8 to Great Groups</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(6, 12)
              }
              style={{
                backgroundColor:
                  actAppData[7] != expAppData[7] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[7]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[7] != expAppData[7] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[7]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>1x/week per female client</td>}

            {isCheckedOccurrence && (
              <td>every Friday from start of programming to day 90</td>
            )}

            <td>Women's Group</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(6, 13)
              }
              style={{
                backgroundColor:
                  actAppData[8] != expAppData[8] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[8]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[8] != expAppData[8] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[8]}
            </td>
          </tr>

          <tr>
            {isCheckedFrequency && <td>1x/week per client, 10 in total</td>}

            {isCheckedOccurrence && (
              <td>
                once per week from start date (one every 7 days) until day 90
              </td>
            )}

            <td rowspan="2">Care Coordination</td>
            <td>30/60/90 Checklist</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(8, 16)
              }
              style={{
                backgroundColor:
                  actAppData[9] != expAppData[9] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[9]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[9] != expAppData[9] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[9]}
            </td>
          </tr>
          <tr>
            {isCheckedFrequency && <td>1 per client</td>}

            {isCheckedOccurrence && <td>1 at day 90</td>}

            <td>PDP</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(8, 15)
              }
              style={{
                backgroundColor:
                  actAppData[10] != expAppData[10] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[10]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[10] != expAppData[10] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[10]}
            </td>
          </tr>

          <tr>
            {isCheckedFrequency && <td>1 mandatory per client</td>}

            {isCheckedOccurrence && <td>1 at day 14</td>}

            <td>Shiloh Alignment</td>
            <td>Shiloh Alignments</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(7, 14)
              }
              style={{
                backgroundColor:
                  actAppData[11] != expAppData[11] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {actAppData[11]}
            </td>
            <td
              style={{
                backgroundColor:
                  actAppData[11] != expAppData[11] ? "#fce2e1" : "#e1fce7",
              }}
            >
              {expAppData[11]}
            </td>
          </tr>

          <tr>
            {isCheckedFrequency && <td>As necessary</td>}

            {isCheckedOccurrence && (
              <td>
                no specific time of occurrence, just include number of
                appointments booked in report
              </td>
            )}

            <td rowspan="2">Assessors</td>
            <td>Extensions</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(9, 20)
              }
              // style={{
              //   backgroundColor:
              //     actAppData[12] != expAppData[12] ? "#fce2e1" : "#e1fce7",
              // }}
            >
              {actAppData[12]}
            </td>
            <td>-</td>
          </tr>
          <tr>
            {isCheckedFrequency && (
              <td>
                no specific time of occurrence, just include number of
                appointments booked in report
              </td>
            )}

            {isCheckedOccurrence && <td>Step Downs</td>}

            <td>Step Downs</td>
            <td
              onClick={() =>
                getAppointmentsByClientDepartmentSubDepartment(9, 19)
              }
              // style={{
              //   backgroundColor:
              //     actAppData[13] != expAppData[13] ? "#fce2e1" : "#e1fce7",
              // }}
            >
              {actAppData[13]}
            </td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
      {isOpenModel && (
        <AppointmentModel
          handleOpenEvent={handleOpenEvent}
          isOpen={isOpenModel}
          data={individualAppointmentData}
        />
      )}
    </div>
  );
};

export default ClientComplianceTable;
