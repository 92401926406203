import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LogoutModal from "../../logoutModal/LogoutModal";

const UpdateProgram = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const role = useAuth().role;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [programName, setprogramName] = useState(data.name);
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);
  const [values, setValues] = useState([]);

  // console.log(`data : `, data);

  const handleAddClick = () => {
    if (programName === "") {
      toast.warn("Program Name Should not be Empty!!");
      return;
    }

    // console.log(`values : `, values);

    const payload = {
      ProgramID: data.id,
      ProgramName: programName,
      updatedBy: userId,
      values: values,
    };
    // console.log(`payload : `, payload);
    // return ;

    fetch(`${base_url}/program/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Program Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating Program:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  const handleDelete = () => {
    const apiUrl = `${base_url}/department/delete/${data[0].split(":")[0]}`;
    if (
      window.confirm("Are you sure you want to delete this department?") ==
      false
    ) {
      toast.info("department deletion cancelled");
      return;
    }

    // console.log(`apiUrl : `, apiUrl);
    // return;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.status === 404) {
          toast.warn(
            "data is present for this department. Please delete that data first"
          );
          return;
        } else if (response.status === 200) {
          toast.success("department deleted successfully");
          return;
        } else {
          setIsExpire(true);
          return;
        }
      })
      .catch((error) => {
        toast.error("Error deleting department");
        console.error("Error sending data:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  const handleDropdownChange = (index, selectedValue) => {
    const updatedValues = [...values];
    updatedValues[index].frequency_type = selectedValue;
    setValues(updatedValues);
  };

  const handleNumberChange = (index, newValue) => {
    const updatedValues = [...values];
    updatedValues[index].frequency_value = newValue;
    setValues(updatedValues);
  };

  const getProgramData = () => {
    const payload = {
      ProgramID: data.id,
    };

    fetch(`${base_url}/program/rules`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        const data = await response.json();
        // console.log(`response: `, data);

        setValues(data);

        // toast.success("Program Updated Successful!");
        // onClose();
      })
      .catch((error) => {
        console.error("Error Updating Program:", error);
      })
      .finally(() => {
        // onClose();
      });
  };

  useEffect(() => {
    getProgramData();
  }, []);

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Department Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isExpire && (
          <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
        )}
        <div className="">
          <div className="d-flex align-items-center mb-3">
            <input
              type="text"
              id="name"
              className="form-control "
              value={programName}
              onChange={(e) => setprogramName(e.target.value)}
              placeholder="Program Name"
            />
          </div>

          {values &&
            values.length > 0 &&
            values.map((item, index) => (
              <div key={index} className="d-flex align-items-center gap-3 mb-3">
                <label htmlFor={`name-${index}`} className="w-25">
                  {/* {item.split(":")[1]} */}
                  {item?.sub_department}
                </label>

                <select
                  id={`dropdown-${index}`}
                  className="form-select w-50"
                  value={values[index]?.frequency_type || "one-time"}
                  onChange={(e) => handleDropdownChange(index, e.target.value)}
                >
                  <option value="one-time">One Time</option>
                  <option value="weekly">Weekly</option>
                  <option value="custom">Custom</option>
                  <option value="biweekly">Biweekly</option>
                  <option value="monthly">Monthly</option>
                </select>

                <input
                  type="number"
                  min="0"
                  className="form-control"
                  style={{ width: "15%" }}
                  value={values[index]?.frequency_value || 0}
                  onChange={(e) =>
                    handleNumberChange(index, Number(e.target.value))
                  }
                />
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {/* {
            role === "Super Admin" && <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
          } */}
        <Button variant="primary" onClick={handleAddClick}>
          Update Program
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProgram;
