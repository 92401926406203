import React, { useCallback, useEffect, useRef, useState } from "react";
import LogoutModal from "../../logoutModal/LogoutModal";
import Message from "./Message";
import "./chats.css";
import { useAuth } from "../../../context/useAuth";

const Chats = () => {
  const [isExpire, setIsExpire] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [messages, setMessages] = useState([]);
  const token = useAuth().token;
  const base_url = process.env.REACT_APP_BASE_URL;

  const dummyMessages = [
    {
      id: 1,
      text: "Hey there! How's it going?",
      sender: "other",
      //   timestamp: "10:00 AM",
    },
    {
      id: 2,
      text: "I'm good, thanks! How about you?",
      sender: "user",
      //   timestamp: "10:02 AM",
    },
    {
      id: 3,
      text: "Doing well! Just working on some React projects.",
      sender: "other",
      //   timestamp: "10:05 AM",
    },
    {
      id: 4,
      text: "That sounds interesting. What are you building?",
      sender: "user",
      //   timestamp: "10:07 AM",
    },
    {
      id: 5,
      text: "A chat application with React. It's coming along nicely!",
      sender: "other",
      //   timestamp: "10:10 AM",
    },
    {
      id: 6,
      text: "That's awesome! I'd love to see it when it's done.",
      sender: "user",
      //   timestamp: "10:12 AM",
    },
    {
      id: 7,
      text: "Sure thing! I'll share it with you soon.",
      sender: "other",
      //   timestamp: "10:15 AM",
    },
    {
      id: 8,
      text: "Looking forward to it!",
      sender: "user",
      //   timestamp: "10:17 AM",
    },
    {
      id: 9,
      text: "By the way, have you tried the new React features?",
      sender: "other",
      //   timestamp: "10:20 AM",
    },
    {
      id: 10,
      text: "Not yet, but I've been meaning to check them out.",
      sender: "user",
      //   timestamp: "10:22 AM",
    },
    {
      id: 11,
      text: "They're really cool. Especially the new hooks.",
      sender: "other",
      //   timestamp: "10:25 AM",
    },
    {
      id: 12,
      text: "I'll definitely look into them then!",
      sender: "user",
      //   timestamp: "10:27 AM",
    },
    {
      id: 13,
      text: "Let me know if you need any help with them.",
      sender: "other",
      //   timestamp: "10:30 AM",
    },
    { id: 14, text: "Will do, thanks!", sender: "user", timestamp: "10:32 AM" },
    {
      id: 15,
      text: "So what else have you been up to lately?",
      sender: "other",
      //   timestamp: "10:35 AM",
    },
  ];

  const messagesEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleQuery = () => {
    if (userQuery === "") {
      return;
    }
    setUserQuery("");
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: userQuery, sender: "user" },
    ]);

    // make api call here

    const apiUrl = `${base_url}/chat/query`;

    let payload = {
      query: userQuery,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions).then(async (response) => {
      const data = await response.json();
      // console.log(`data : `, data);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.text, sender: "bot" },
      ]);
    });
    return;
  };

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // console.log("Enter key pressed!");
        // Add your custom logic here
        handleQuery();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4 ">Chats with AI</h3>
      <hr />

      <div>
        <div className="chat-container">
          <div className="messages-container">
            {messages.map((message, index) => (
              <Message key={index} message={message} />
            ))}
            <div ref={messagesEndRef} />
          </div>

          <div className="chat-input-container">
            <input
              type="text"
              placeholder="Type a message..."
              className="chat-input"
              value={userQuery}
              onChange={(e) => setUserQuery(e.target.value)}
            />
            <button onClick={handleQuery} className="send-button">
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;
