import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./tableStyles.css";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import moment from "moment";

const ClientTable = ({ clientData, handleActionClick }) => {
  // console.log(`data :`, clientData);

  const [data, setData] = useState(clientData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const today = new Date(Date.now());
  useEffect(() => {
    setData(clientData);
  }, [clientData]);

  const sortData = (key) => {
    let direction = "asc";

    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      const valueA = a[key] ?? ""; // Handle null/undefined
      const valueB = b[key] ?? ""; // Handle null/undefined

      if (valueA > valueB) return direction === "asc" ? 1 : -1;
      if (valueA < valueB) return direction === "asc" ? -1 : 1;
      return 0;
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const getSortIcon = (key) =>
    sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      )
    ) : (
      <FaSort />
    );

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  function convertTimeToDate(dateString) {
    if (!dateString || dateString === "null") {
      return "";
    }
    const newDate = moment.utc(dateString).format("MM/DD/YYYY");
    return newDate;
  }

  return (
    <div className="table-wrapper">
      <div className="table-container">
        <table
          className="table table-bordered"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead className="table-light">
            <tr>
              <th
                style={{ width: "100px", textAlign: "center" }}
                className="sticky-col"
              >
                Action
              </th>
              <th
                style={{ width: "60px" }}
                className="sticky-col"
                onClick={() => sortData("ClientID")}
              >
                Id {getSortIcon("ClientID")}
              </th>
              <th
                style={{ width: "200px" }}
                className="sticky-col"
                onClick={() => sortData("name")}
              >
                Name {getSortIcon("name")}
              </th>
              <th
                style={{ width: "130px" }}
                className="sticky-col"
                onClick={() => sortData("dob")}
              >
                Date of Birth {getSortIcon("dob")}
              </th>

              <th
                style={{ width: "230px" }}
                onClick={() => sortData("clientAMPMGroup")}
              >
                SAIOP AM or PM Group {getSortIcon("clientAMPMGroup")}
              </th>

              <th
                style={{ width: "130px" }}
                onClick={() => sortData("IntakeDate")}
              >
                Intake Date {getSortIcon("IntakeDate")}
              </th>
              <th
                style={{ width: "130px" }}
                onClick={() => sortData("sud_doa")}
              >
                SUD DOA {getSortIcon("sud_doa")}
              </th>
              <th
                style={{ width: "130px" }}
                onClick={() => sortData("ProgramCompletionDate")}
              >
                Completion Date {getSortIcon("ProgramCompletionDate")}
              </th>
              <th
                style={{ width: "130px" }}
                onClick={() => sortData("DischargeDate")}
              >
                Discharge Date {getSortIcon("DischargeDate")}
              </th>
              <th
                style={{ width: "90px" }}
                onClick={() => sortData("daysBetweenIntakeAndDischarge")}
              >
                Days {getSortIcon("daysBetweenIntakeAndDischarge")}
              </th>

              <th
                style={{ width: "100px" }}
                onClick={() => sortData("ActiveStatus")}
              >
                Status {getSortIcon("ActiveStatus")}
              </th>
              <th style={{ width: "230px" }} onClick={() => sortData("Email")}>
                Email {getSortIcon("Email")}
              </th>
              <th style={{ width: "160px" }} onClick={() => sortData("Phone")}>
                Phone {getSortIcon("Phone")}
              </th>
              <th
                style={{ width: "250px" }}
                onClick={() => sortData("updated_by")}
              >
                Updated by {getSortIcon("updated_by")}
              </th>
              <th style={{ width: "200px" }} onClick={() => sortData("notes")}>
                Notes {getSortIcon("notes")}
              </th>
              <th
                style={{ width: "200px" }}
                onClick={() => sortData("created_by")}
              >
                Created by {getSortIcon("created_by")}
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((row) => (
              <tr key={row.id}>
                <td
                  className="sticky-col"
                  style={{ textAlign: "center", padding: "4px" }}
                >
                  <button
                    className="btn btn-primary"
                    style={{
                      height: "30px",
                      fontSize: "15px",
                      padding: "0px 10px",
                    }}
                    onClick={() => handleActionClick(row.ClientID)}
                  >
                    Action
                  </button>
                </td>
                <td className="sticky-col">{row.ClientID}</td>
                <td className="sticky-col">{row.name}</td>
                <td className="sticky-col">{convertTimeToDate(row.dob)}</td>
                <td>{row.clientAMPMGroup}</td>
                <td>{convertTimeToDate(row.IntakeDate)}</td>
                <td>{convertTimeToDate(row.sud_doa)}</td>
                <td
                  style={{
                    color:
                      new Date(row.ProgramCompletionDate).getTime() <=
                      today.getTime()
                        ? "#BB2B3D"
                        : "black",
                    fontWeight:
                      new Date(row.ProgramCompletionDate).getTime() <=
                      today.getTime()
                        ? "bold"
                        : "",
                  }}
                >
                  {convertTimeToDate(row.ProgramCompletionDate)}
                </td>
                <td>{convertTimeToDate(row.DischargeDate)}</td>
                <td>{row.daysBetweenIntakeAndDischarge}</td>

                <td
                  style={{
                    color: row.ActiveStatus == 1 ? "black" : "#BB2B3D",
                    fontWeight: row.ActiveStatus == 1 ? "" : "bold",
                  }}
                >
                  {row.ActiveStatus == 1 ? "Active" : "Inactive"}
                </td>
                <td>{row.Email == "" ? "No Email" : row.Email}</td>
                <td>{row.Phone == "" ? "No Phone" : row.Phone}</td>
                <td>{row.updated_by}</td>
                <td>{row.notes}</td>
                <td>{row.created_by}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <button
          className="btn btn-secondary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span className="mx-3">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-secondary"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ClientTable;
