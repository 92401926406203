import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import LogoutModal from "../../logoutModal/LogoutModal";
import { useAuth } from "../../../context/useAuth";
import AddProgram from "./AddProgram";
import UpdateProgram from "./UpdateProgram";

const Program = () => {
  const [isExpire, setIsExpire] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [openAddProgram, setOpenAddProgram] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getPrograms();
  };

  const getPrograms = async () => {
    try {
      const response = await fetch(`${base_url}/program/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log("programs", data);
      setPrograms(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const getSubdepartments = async (val) => {
    try {
      const response = await fetch(`${base_url}/subdepartment/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setSubdepartments(temp);
    } catch (error) {
      console.error("Error fetching subdepartments:", error);
    }
  };

  const handleActionClick = (e) => {
    setActionModalData(e);
    setIsActionModalOpen(true);
  };

  useEffect(() => {
    getPrograms();
    getSubdepartments();
  }, []);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => row.id,
      sortable: false,
    },
    {
      name: "Program Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row)}
        >
          Action
        </button>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px", // Row text size
        color: "black", // Row text color
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const handleAddProgram = () => {
    setOpenAddProgram(true);
  };

  const handleCloseAddProgram = async () => {
    setOpenAddProgram(false);
    await getPrograms();
  };

  return (
    <>
      <div className="">
        {isExpire && (
          <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
        )}
        <h3 className="pt-4">Programs</h3>
        <hr />
        {openAddProgram && (
          <AddProgram
            Subdepartments={Subdepartments}
            isOpen={openAddProgram}
            onClose={handleCloseAddProgram}
          />
        )}

        {isActionModalopen && (
          <UpdateProgram
            Subdepartments={Subdepartments}
            isOpen={isActionModalopen}
            onClose={closeActionModal}
            data={actionModalData}
          />
        )}

        <div className="row mt-1 ms-1 mb-3">
          <button
            className="btn btn-primary me-5"
            type="button"
            style={{ width: "210px" }}
            onClick={handleAddProgram}
          >
            Add A Program
          </button>
        </div>

        <DataTable
          pagination
          customStyles={customStyles}
          highlightOnHover
          columns={columns}
          data={programs}
        />
      </div>
    </>
  );
};

export default Program;
