import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import LogoutModal from "../../logoutModal/LogoutModal";

const FilterForm = ({ onSubmit, fetchData }) => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const programId = useAuth().programId;
  const [department, setDepartment] = useState("");
  const [subdepartment, setSubdepartment] = useState("");
  const [Subdepartments, setSubdepartments] = useState([]);
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate1, setStartDate1] = useState("1970-01-01");
  const [endDate1, setEndDate1] = useState("2070-01-01");
  const [Departments, setDepartments] = useState([]);
  const [attendance, setAttendance] = useState(false);
  const [excusedAbsence, setExcusedAbsence] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [isUnchecked, setIsUnchecked] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const handleStartDateChange = (selectedDate) => {
    setStartDate(selectedDate);
    setStartDate1(selectedDate);
  };

  const handleEndDateChange = (selectedDate) => {
    setEndDate(selectedDate);
    setEndDate1(selectedDate);
  };

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
  };

  const handleUserTypeaheadChange = (selected) => {
    setUser(selected);
  };

  const handleAttendanceChange = (e) => {
    setAttendance(e.target.checked);
  };

  const handleExcusedAbsenceChange = (e) => {
    setExcusedAbsence(e.target.checked);
  };

  const handleUncheckedChange = (e) => {
    setIsUnchecked(e.target.checked);
  };

  const handleCancelledChange = (e) => {
    setIsCancelled(e.target.checked);
  };

  function convertTimeToDate(timeString) {
    const date = new Date(timeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(`client: `, client);
    // console.log(`user: `, user);
    // console.log(`clients : `, clients);

    let clientID;

    if (client.length !== 0) {
      clientID = client[0].split(":")[0];
    } else {
      clientID = "-1";
    }

    // return;

    // var clientID = await new Promise((resolve, reject) => {
    //   clients.forEach((elem) => {
    //     if (elem.includes(client[0])) {
    //       resolve(elem.split(":")[0]);
    //     }
    //   });
    //   resolve(-1);
    // });

    // console.log(`users : `, users);
    // console.log(`user: `, user);

    let userId;

    if (user.length !== 0) {
      userId = user[0].split(":")[0];
    } else {
      userId = "-1";
    }
    // return;

    // var userId = await new Promise((resolve, reject) => {
    //   users.forEach((elem) => {
    //     if (elem.includes(user[0])) {
    //       resolve(elem.split(":")[0]);
    //     }
    //   });
    //   resolve(-1);
    // });

    var startDateAppointment = "1970-01-01";
    var endDateAppointment = "2070-01-01";

    if (startDate != null) {
      startDateAppointment = convertTimeToDate(startDate);
    }

    if (endDate != null) {
      endDateAppointment = convertTimeToDate(endDate);
    }

    onSubmit(
      department,
      subdepartment,
      clientID,
      startDateAppointment,
      endDateAppointment,
      attendance,
      excusedAbsence,
      userId,
      isUnchecked,
      isCancelled
    );
    fetchData({
      deptID: department,
      subdeptID: subdepartment,
      clientID: clientID,
      startDate: startDateAppointment,
      endDate: endDateAppointment,
      attendance: attendance,
      excusedAbsence: excusedAbsence,
      userId: userId,
      isUnchecked: isUnchecked,
      isCancelled: isCancelled,
    });
  };

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setDepartments(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const getSubdepartments = async (val) => {
    try {
      const response = await fetch(`${base_url}/subdepartment/deptId/${val}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setSubdepartments(temp);
    } catch (error) {
      console.error("Error fetching subdepartments:", error);
    }
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
        }
        resolve(temp);
      });
      setClients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await fetch(`${base_url}/user/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      // console.log("users : ", data);

      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["UserID"]}:${data[i]["UserName"]}`);
        }
        resolve(temp);
      });

      setUsers(temp);
    } catch (err) {}
  };

  useEffect(() => {
    getDepartments();
    getClients();
    getUsers();
  }, []);

  return (
    <>
      <h3 className="pt-4">Reports</h3>
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <hr />
      <form onSubmit={handleSubmit} className="">
        <div className="mb-3 row">
          <label htmlFor="department" className="col-sm-2 col-form-label">
            Department
          </label>
          <div className="col-sm-10">
            <select
              className="form-select w-50"
              id="department"
              aria-label="Default select example"
              onChange={async (e) => {
                if (e.target.value != "") {
                  setDepartment(e.target.value);
                  setSubdepartment("");
                  await getSubdepartments(e.target.value);
                } else {
                  setDepartment("");
                  setSubdepartments([]);
                }
              }}
            >
              <option value="">All Departments</option>
              {Departments.map((option, index) => (
                <option key={index} value={option.split(":")[0]}>
                  {option.split(":")[1]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="subdepartment" className="col-sm-2 col-form-label">
            Sub Department
          </label>
          <div className="col-sm-10">
            <select
              className="form-select w-50"
              id="subdepartment"
              defaultValue=""
              aria-label="Default select example"
              value={subdepartment}
              onChange={async (e) => {
                if (e.target.value != "") {
                  setSubdepartment(e.target.value);
                } else {
                  setSubdepartment("");
                }
              }}
            >
              <option value="">All Sub Departments</option>
              {Subdepartments.map((option, index) => (
                <option key={index} value={option.split(":")[0]}>
                  {option.split(":")[1]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="client" className="col-sm-2 col-form-label">
            Client &nbsp;&nbsp;
          </label>
          <div className="col-sm-10">
            <Typeahead
              id="client"
              labelKey={(option) => `${option.split(":")[1]}`}
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem)}
              selected={client}
              className="w-50"
              placeholder="Select a Client"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="user" className="col-sm-2 col-form-label">
            User Email &nbsp;&nbsp;
          </label>
          <div className="col-sm-10">
            <Typeahead
              id="user"
              onChange={handleUserTypeaheadChange}
              labelKey={(option) => `${option.split(":")[1]}`}
              options={users.map((elem) => elem)}
              selected={user}
              className="w-50"
              placeholder="Select a User Email"
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="StartdatePicker" className="col-sm-2 col-form-label">
            Start Date&nbsp;&nbsp;&nbsp;
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="StartdatePicker"
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="MM/dd/yyyy"
              className="form-control"
              maxDate={new Date(endDate1)}
              placeholderText="Select Start Date"
              autoComplete="off"
            />
          </div>
        </div>
        <div className=" mb-3 row">
          <label htmlFor="enddatePicker" className="col-sm-2 col-form-label">
            End Date&nbsp;&nbsp;&nbsp;
          </label>
          <div className="col-sm-10">
            <DatePicker
              id="enddatePicker"
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="MM/dd/yyyy"
              className="form-control"
              minDate={new Date(startDate1)}
              placeholderText="Select End Date"
              autoComplete="off"
              // maxDate={new Date(endDate1)}
            />
          </div>
        </div>

        <div className="mb-2">
          <input
            className="me-2"
            type="checkbox"
            role="switch"
            id="attendence"
            autoComplete="off"
            onChange={handleAttendanceChange}
          />
          <label className="" htmlFor="attendence">
            Check for Attendance
          </label>
        </div>

        <div className="mb-2">
          <input
            className="me-2"
            type="checkbox"
            role="switch"
            id="excusedabsence"
            autoComplete="off"
            onChange={handleExcusedAbsenceChange}
          />
          <label className="" htmlFor="excusedabsence">
            Check for Excused Absence
          </label>
        </div>

        <div className="mb-2">
          <input
            className="me-2"
            type="checkbox"
            role="switch"
            id="unchecked"
            autoComplete="off"
            onChange={handleUncheckedChange}
          />
          <label className="" htmlFor="unchecked">
            Check for Unchecked Appointments
          </label>
        </div>

        <div className="mb-3">
          <input
            className="me-2"
            type="checkbox"
            role="switch"
            id="cancelled"
            autoComplete="off"
            onChange={handleCancelledChange}
          />
          <label className="" htmlFor="cancelled">
            Check for Cancelled Appointments
          </label>
        </div>

        <button className="btn btn-primary" type="submit">
          Submit
        </button>
      </form>
    </>
  );
};

export default FilterForm;
